$publicPath:'/tantan-door-admin';











































































@import "./../assets/css/global-variable.scss";
.g-error-box{
  background: #ffffff;
  position: relative;
  width: 100%;
  height: 100%;
}
.g-wrapper{
  width: 100%;
  height: 300px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.g-wrapper .code{
  font-size: 36px;
  letter-spacing: 0px;
  margin-top: 20px;
  color: $fc-theme;
}
.g-wrapper-txt{
  position: relative;
  color: #666666;
  top: 100px;
  left: 0;
  right: 0;
  margin:0 auto;
  text-align: center;
  font-size: 24px;
}
